#alertBlanket,
#confirmBlanket,
#signinBlanket,
#passwordBlanket,
#eCertBlanket,
#processingBlanket,
#applyingpromoBlanket,
#removingpromoBlanket,
#applyingOverrideBlanket,
#removingOverrideBlanket,
#evrBookingAlertBlanket {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: .75;
	filter: alpha(opacity=75);
	z-index: 1000;
}

/* Dialog Wrapper CSS */

#dialogWrapper {
	position: fixed;
	top: 15%;
	left: 50%;
	margin: 0 0 0 -261px;
	z-index: 1500;

	#theBorder {
		background-color: #393939;
		width: 522px;
		height: 100%;
		border-radius: 10px;
	}

	#theMsg {
		background-color: #373737 !important;
		border: 2px solid #696567;
		padding: 15px 15px 20px 15px;
		height: 100%;
		border-radius: 10px;

		.leftArea {
			width: 200px;
			border-right: 1px solid #696567;
			padding-right: 43px;
			margin-top: 10px;
		}

		.rightArea {
			width: 200px;
			padding-left: 44px;
			margin-top: 10px;
		}

		#closeBlanket {
			margin-top: -27px;
			margin-left: -27px;
			width: 24px;
			height: 24px;
			background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/btn_close_popup.png") no-repeat;
		}

		.loader-image-container {
				width: 50px;
				height: 50px;
				background-image: url(@processingLoaderImage);
				margin: 10px 0 0 -25px;
				position: relative;
				left: 50%;
		}
	}
}

.helper-open-button {
	cursor: pointer;
}

.helper-container {
	position: absolute;
	display: block;
	width: 300px;
	text-align: left;
	background-color: #fafafa;
	border: 1px solid #c8c8c8;
	.box-shadow(4px 4px 4px -2px #666666);
	padding: 10px;
	margin: 0;
	z-index: 1000;

	&.helper-container-attached-lists {
		width: auto;
		min-width: 300px;
	}

	.helper-close-button-container {
		position: relative;

		.helper-close-button {
			position: absolute;
			content: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/close_symbol.gif");
			right: 0;
			cursor: pointer;
		}
	}

	.helper-header {
		font-size: 18px;
		font-weight: normal;
		line-height: 1.2em;
		font-family: 'Lato', sans-serif;
		color: #13458a;
		border-bottom: 1px solid #c8c8c8;
		margin: 0px 0 10px 0;
		padding: 2px;

		& + .helper-sub-header {
			margin-top: 0;
		}
	}

	.helper-sub-header,
	.helper-callout {
		font-size: 12px;
		font-family: 'Lato', sans-serif;
		font-weight: bold;
		color: #13458a;
		margin: 8px 0 4px 0;
	}

	.helper-callout {
		margin: 0;

		&.inactive {
			color: #999999;
		}
	}

	.helper-textarea {
		width: 290px;
		height: 160px;
		margin-bottom: 10px;
	}

	.helper-message {
		font-family: Arial;
		font-size: 11px;
		color: #646260;
		margin: 0 0 4px 0;
	}

	&.none {
		 display: none;
	}
}

.no-hold-alert {
	position: absolute;
	width: 242px;
	background-color: #fafafa;
	border: 1px solid #c8c8c8;
	padding: 10px;
	.box-shadow(4px 4px 4px -2px #666666);

	.no-hold-alert-message {
		font-family: Arial;
		font-size: 11px;
		font-weight: bold;
		color: #ff0000;
		margin: 0px;
	}
}

.popupFormInput {
	width: 190px;
	height: 15px;
}
a.popupAnchor,
a.popupAnchor:hover,
a.popupAnchor:visited,
a.popupAnchor:active,
a.popupAnchor:link {
	font-family: Arial;
	font-size: 10px;
	font-weight: bold;
	color: #498ce7;
	text-decoration: none;
}
.popupText {
	font-family: Arial;
	font-size: 11px;
	font-weight: normal;
	color: #aaaaaa;
	padding: 0px;
	margin: 0px;
	text-align: left;
}
.popupFormLabel {
	font-family: Arial;
	font-size: 11px;
	font-weight: normal;
	color: #ffffff;
	margin: 0px 0px 2px 0px;
	text-align: left;

	&.alert {
		color: #d31900;
	}
}
.popupHeader {
	font-family: 'Lato',sans-serif;
	font-size: 20px;
	color: #ffffff;
	padding: 0px;
	margin: 3px 0 15px 0;
	text-align: left;
}
#allInclusiveMessage {
	margin-bottom: 10px;
	color: #aaaaaa;

	.resort-date-specificai-info-container {
		max-height: 175px;
		overflow-y: auto;
	}
}