.clearfix {
	.clearfix();
}

.row() {
	.clearfix();
	> * {
		float: left;
	}
}

.row {
	.row();
}


.box {
	#gradient .vertical(#ffffff,#dbdbdb);
	padding: 10px;
	margin: 20px 0 0;
	border: 1px solid #c8c8c8;

	.header {
		font-size: 14px;
		line-height: 18px;
		margin: 0 0 8px 0;
		text-transform: uppercase;
	}
}

.breadcrumb-navigation {
	margin: 0 0 10px 0;
	
}

// Inputs, Textareas, Selects
input,
textarea,
select,
.uneditable-input {
	display: inline-block;
	padding: 2px;
	font-size: 11px;
	height: 15px;
	line-height: 15px;
	color: #666666;
	border: 1px solid #b8b8b8;
}

// remove padding from select
select {
	height: 19px;
	line-height: 19px;
	padding: initial;
}

input[readonly],
select[disabled] {
	color: #aaaaaa;
}

input[type=file] {
	height: auto;
}

textarea {
	height: auto;
}

// mini reset for non-html5 file types
input[type=checkbox],
input[type=radio] {
	width: auto;
	height: auto;
	padding: 0;
	margin: 3px 0;
	*margin-top: 0; /* IE6-7 */
	line-height: normal;
	border: none;
}

input[type=text],
input[type=password] {

	&.toggle-password-input {
		padding-right: 32px;
		width: 160px;
	}
}

.toggle-password-display {
	top: 1px;
	right: 1px;
	position: absolute;
	text-align: center;
	line-height: 1.1em;
	font-size: 18px;
	font-weight: normal;
	width: 30px;
	height: 19px;
	display: inline-block;
	color: #b8b8b8;
	cursor: pointer;
	text-decoration: none;

	&.fa, &.fas {
		font-weight: normal;
		line-height: 1.1em;
	}
}

.input-small,
input.small,
textarea.small,
select.small {
	font-size: 11px;
	width: 90px;
}
.input-medium,
input.medium,
textarea.medium,
select.medium {
	font-size: 11px;
	width: 130px;

	&.toggle-password-input {
		width: 100px;
	}
}
.input-large,
input.large,
textarea.large {
	font-size: 11px;
	width: 230px;

	&.toggle-password-input {
		width: 200px;
	}
}
select.large {
	font-size: 11px;
	width: 236px;
}

select[type=multiple] {
	height: auto;
}

/* IDS */

div.grayscale {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #4E5253;
	opacity: .70;
	filter: alpha(opacity=70);
	z-index: 1;
}
#bgImg img {
	/* Set rules to fill background */
	min-height: 100%;
	min-width: 1024px;

	/* Set up proportionate scaling */
	width: 100%;
	height: auto;

	/* Set up positioning */
	position: fixed;
	top: 0;
	left: 0;

	pointer-events: none;
	z-index: 0;
}
@media screen and (max-width: 1030px) { /* Specific to this particular image */
	#bgImg img,
	#bgImgHome img {
		left: 50%;
		margin-left: -515px;   /* 50% */
	}
}

/* ELEMENTS */
a {
	color: #498ce7;
	text-decoration: none;
	cursor:pointer;
}
a:hover {
	text-decoration: underline;
}
body {
	padding: 0;
	margin: 0;
}
body, table, form, input, select {
	font-family: Verdana;
	font-size: 11px;
	font-weight: normal;
	line-height: 14px;
	color: #646260;
}
body#noBg {
	background: #ffffff none;
}
body#printerFriendly {  
	background: #ffffff none; 
}
input.disabledEmail {
	background-color: #EBEBE4;
}
form, div {
	margin: 0;
	padding: 0;
}
hr {
	border: 0;
	border-top: 1px solid #d6d6d6;
	padding: 0;
	margin: 0;
}
img {
	border: 0;
	display: block;

	&.inline {
		display: inline-block;
	}
}

li {
	margin: 0 0 5px 5px;
}
p {
	margin: 0 0 12px 0;
}
ul, ol {
	margin: 8px 0 4px 0;
}
sup {
	font: normal normal 8px Verdana, Arial, Helvetica, sans-serif;
}

#monthgrid th {
	background-color: #746f66;
	color: #ffffff;
}
tr {
	vertical-align: top;
}
ul, ol {
	list-style-position: outside;
	margin: 8px 0 4px 0;
	padding: 0 0 0 13px;
}

/* CLASSES */
.alert,
.alert div {
	color: #d31900 !important;
}
.alignCenter {
	text-align: center;
}
.alignLeft {
	text-align: left;
}
.alignRight {
	text-align: right;
}
div.bg {
	padding: 0;
	margin: 0;
	position: relative; 
	z-index: 2;
}
div.bgHome {
	padding: 0;
	margin: 100px 0px 0px 0px;
	height: 700px;
}
div.bgMain {
	padding: 0;
	margin: 0px 0px 0px 0px;
	height: 100%;
}

.eRubeSubmit {
	cursor: pointer;
}
.callout,
.error,
.error div,
.error p,
.error span {
	color: #ff0000 !important;
}
ul.cancelPolicy {
	margin: 0 0 8px 0;
	padding: 0 0 0 13px;
}
.clearFloat {
	clear: both;
	display: block;
	margin: 0;
	padding: 0;
	height: 0;
	line-height: 1px;
	font-size: 1px;
}
.displayBlock {
	display: block;
}
.displayNone {
	display: none;
}
.errorBox {
	background: #fbf3f3;
	padding: 10px;
	margin: 20px 0 0 0;
	border: 1px solid #ff0000;

	p, div {
		color: #ff0000;
		margin: 0;

		& + p, & + div {
			margin-top: 5px;
		}
	}

	a {
		color: #ff0000;
		text-decoration: underline;
	}
}
.eRubeDivContainer {
	margin: 0;
	padding: 0;
}
.floatLeft {
	float: left;
}
.floatRight {
	float: right;
}
.formfield {
	margin: 0 0 8px 0;
	font-size: 10px;

	&.relative {
		display: inline-block;
		position: relative;
	}
}
.formlabel {
	margin: 0 0 2px 0;
	color: #666666;
	font-size: 10px;
}
.formlabel div {
	color: #666666;
	font-size: 10px;
}
.formlabelalert {
	margin: 0 0 2px 0;
	color: #d31900;
	font-size: 10px;
}
.formlabelalert div {
	color: #ff0000;
	font-size: 10px;
}
.frmBtn {
	margin: 12px 0 4px 0;
}
.gradBox {
	background: #f7fafb url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/grad_gradbox.gif") repeat-x bottom left;
	padding: 7px 20px 11px;
	margin: 15px 0;
	border: 1px solid #c2d6dd;
}

.limitedTimeBox {
	background-color: #f1f9fa;
	padding: 10px;
	border-top: 1px solid #c2d5dc;
	border-bottom: 1px solid #c2d5dc;
}
.medium,
.medium div {
	font-size: 11px;
}

.tile {
	height: 2px;
	padding: 0;
	margin: 0;
	clear: both;
	display: block;
	margin: 0;
	padding: 0;
	line-height: 1px;
	font-size: 1px;
}


.selectWrpr {
	height: 19px;
}
.small {
	font-size: 10px;
}
.smaller {
	font-size: 9px;
}

.upgradeOptions {
	margin: 15px 0 0 0;
	padding: 15px 0;
	border-top: 1px solid #cac8c5;
	border-bottom: 1px solid #cac8c5;
}
.valignTop {
	vertical-align: top;
}
.formfieldtight {
	margin: 0 0 4px 0;
	font-size: 10px;
}
td.totalGrand {
	font-weight: bold;
	color: #44a5c3;
	text-align: right;
}
.totalValue {
	font-weight: bold;
}
.cursorPointer {
	cursor: pointer;
}
.noCursor {
	cursor: default !important;
}


#footerBodyHome {
	position: fixed;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	margin: 0px 0px 0px 0px;
	width: 100%;
	z-index: 2;
}

@media screen and (max-height: 670px) {
	#footerBodyHome {
		position: relative;
	}
}

#footerBodyHome #footer {
	background-color: #ffffff;
	width: 950px;
	margin: 0px 0 0px 0;
	padding: 20px 0;
	text-align: left;
}
#footerBody {
	position: relative;
	background-color: #ffffff;
	padding: 0;
	margin: 0px 0px 0px 0px;
	width: 100%;
	z-index: 2;
}
#footerBody #footer {
	background-color: #ffffff;
	width: 950px;
	margin: 0px 0 0px 0;
	padding: 20px 0;
	text-align: left;
}
#footer #copyright {
	margin: 0;
	padding: 0;
	width: 950px;
}
#footer #copyright p,
#footer #copyright div {
	color: #a2a2a2;
	font-size: 10px;
}
#footer #legalPrivacy {
	width: 765px;
	margin: 0;
	padding: 0px 0px 30px 0px;
}
#footer #legalPrivacy a {
	font-weight: bold;
	text-decoration: none;
}
#footer #legalPrivacy .option,
#footer #legalPrivacy .optionOn,
#footer #legalPrivacy .separator {
	float: left;
	color: #bcd0d7;
	font-size: 10px;
}
#footer #legalPrivacy .separator {
	margin: 0 6px 0 6px;
}
#footer #legalPrivacy .option a, #footer #legalPrivacy .optionOn a {
	font-weight: bold;
	text-decoration: none;
}
#footer #footerLogos {
	float: right;
	width: 185px;
	padding: 0;
}
#footer #footerLogos p {
	margin: 8px 0 9px 0;
}
#footerSeparator {
	height: 10px;
	margin: 10px 0 15px 0;
	padding: 0;
	border-top: 1px solid #d6dfe2;
}
#homeFlash {
	margin: 0 0 2px 0;
}
#homeRightCol {
	float: left;
	padding: 65px 0 0 0;
}
#homeRightCol .tile {
	margin: 0 0 8px 0;
}
#outagearea
{
	margin: 0;
	padding: 0px 0;
	height: 50px;
	width:950px;
	float: left;
}
#logoarea {
	margin: 0;
	padding: 15px 0;
	height: 90px;
	width:600px;
	float: left;
}
#logoarea #logo {
	padding: 0;
	margin: 0;
}

.TODO {
	background-color: yellow;
}
.TODO:before {
	background-color: red;
	color: white;
	font-weight: bold;
	content: "TODO: ";
}

h1 {
	font-family: 'Lato', sans-serif;
	font-size: 27px;
	line-height: 27px;
	font-weight: normal;
	margin: 0 0 15px 0;
	color: #002b66;
}
h2 {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: normal;
	line-height: 20px;
	margin: 4px 0 15px 0;
	color: #13458a;
}
h3 {
	font-family: 'Lato', sans-serif;
	font-weight: normal;
	font-size: 20px;
	line-height: 20px;
	margin: 0;
	color: #13458a;
}
h4 {
	font-family: 'Lato', sans-serif;
	font-weight: normal;
	font-size: 11px;
	margin: 0;
	color: #002b66;
	text-transform: uppercase;
}
h6 {
	font-family: 'Lato', sans-serif;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	margin: 0;
	color: #d31900;
	text-transform: uppercase;
}

/* ------------------ */
/* NEW WORKING STYLES */
/* ------------------ */
.buttonOverlay,
.signInActivateButton,
.forgotPasswordButton,
.alertButton {
	cursor: pointer;
	text-decoration: none !important;
	width: 100%;
	height: 100%;
}
.anchorBlock {
	display: block;
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.password-form-container {
	width: 430px;
}
.marketingTile {
	margin: 0px 0px 2px 0px;
	.box-shadow(0 3px 8px -3px #000000);
}
.tcTextBoxWrapper {
	padding: 20px 20px 20px 20px;
	background: #ffffff;
	width: 650px;
	height: 160px;
	overflow: scroll;
}
.tcTextBoxHeader {
	font: normal bold 15px Trebuchet MS;
	color: black;
	margin-bottom: 20px;
}
.horizLine {
	width: 100%;
	height: 1px;
	border-top: 1px solid #c8c8c8;
	margin: 0px;
	padding: 0px;
}
.noCursor {
	cursor: default !important;
}


#loader {
	&.loader_overlay {
		background: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/loader_overlay.png") repeat scroll 0 0 rgba(0, 0, 0, 0);
		height: 100%;
		width: 100%;
		position: absolute;
		text-align: center;
		top: 0px;
		z-index: 11000;

		div {
			background: none repeat scroll 0 0 #FFFFFF;
			border-radius: 5px;
			box-shadow: 0 1px 3px rgba(0, 53, 98, 0.2);
			color: #555555;
			margin: 50px auto;
			padding: 12px;
			width: 200px;
			font-size: 13px;
		}
	}
}


/* Welcome Intro */
.welcomeHeader {
	padding: 0px;
	margin: 50px 0px 0px 100px;

	& h3.introHeader {
		font-family: 'Caudex';
		font-size: 120px;
		font-style: italic;
		text-transform: none;
		line-height: 120px;
		margin: 0px;
		padding: 0px;
		color: @introHeaderColor;
		text-shadow: 3px 3px 10px @introHeaderTextShadowColor, 3px 3px 10px @introHeaderTextShadowColor;
	}

	& h4.introSubtext {
		font-family: 'Lato',sans-serif;
		font-size: 27px;
		font-weight: normal;
		text-transform: none;
		line-height: 27px;
		margin: 0px;
		padding: 0px;
		color: @introSubtextColor;
	}
}


/* Accept Cookies container */
.footerAcceptCookiesContainer {
	position: fixed; 	
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background-color: #666666;
    opacity: 0.9;
    z-index: 1;

    .footerAcceptCookiesCentered {
    	color: #ffffff;
    	text-align: center;
		vertical-align: middle;
		line-height: 30px;

		.underline {
			text-decoration: underline;
			color: #ffffff;
		}
    }
}
