/* Elements */
.dateRangeAlert {
	float: left;
				padding: 0px 0px 0px 10px;
				color: #ff0000;
				margin: 8px 0 2px 0px;
	font-size: 10px;
}

a {
	font-family: Verdana;
	font-size: 11px;
	color: #7bacbc;
	text-decoration: underline;
}

a.sectionNav,
a.sectionNav:link,
a.sectionNav:visited,
a.sectionNav:hover {
	text-decoration:none;
	font-family: Verdana;
	font-size: 12px;
	font-weight: bold;
	color: #7bacbc;
}
a.sectionNav:hover {
	color: #e22527;
}
a.sectionNavOn,
a.sectionNavOn:link,
a.sectionNavOn:visited,
a.sectionNavOn:hover {
	font-family: Verdana;
	font-size: 12px;
	font-weight: bold;
	color: #e22527;
	text-decoration:none;
}
a.prevNext,
a.prevNext:link,
a.prevNext:visited,
a.prevNext:hover {
	font-family: Arial;
	font-size: 9px;
	text-transform:uppercase;
	font-weight: bold;
	color: #2d99ba;
	text-decoration:none;
}
body {
	margin: 20px;
	background-color: #ffffff;
	padding: 0;
}
body, th, td, form, select, input, textarea {
	color: #000000;
}
div, form {
	margin: 0;
	padding: 0;
}
h1 {
	font-family: Verdana;
	font-weight:bold;
	font-size: 18px;
	margin: 0 0 10px 0;
	color: #000000;
}
hr {
	border: 0;
	border-top: 1px solid #cccccc;
	padding: 0;
	margin: 0;
}

input, select, textarea {
	//height: auto;
}
input[readonly],
select[disabled] {
		color: #666666;
}
// mini reset for non-html5 file types
input[type=checkbox],
input[type=radio] {
	margin: 3px 3px;
	*margin-top: 0; /* IE6-7 */
}
p {
	margin : 0;
}
img {
	border: 0;
}
li {
	margin: 0 0 5px 5px;
}
ul, ol {
	list-style-position : outside;
	margin: 8px 0 4px 0;
	padding : 0px 0px 0px 13px;
}
tt {
	font-size: 13px;
	color: #000000;
}


/* CLASSES */
.addEdit {
	width: 1500px;
	margin: 0 0 20px 0;
}
.addEdit .fieldHeader {
	font-weight: bold;
	margin: 5px 0 3px 0;
}
.addEdit .formLabelFirst {
	float: left;
	width: 170px;
	margin: 5px 10px 3px 0;
}
.addEdit .formLabelLarge {
	float: left;
	width: 350px;
	margin: 5px 10px 3px 0;
}
.addEdit .formLabelNoLeftMargin {
	float: left;
	width: 350px;
	margin: 5px 0px 3px 0;
}
.addEdit .formLabel {
	float: left;
	margin: 6px 10px 3px 0;
}
.addEdit .formLabelFlushRight {
	float: left;
	margin: 6px 0px 3px 0;
}
.addEdit .formField {
	float: left;
	margin: 5px 20px 3px 0;

	&.relative {
		display: inline-block;
		position: relative;
	}
}
.addEdit .formFieldFlushRight {
	float: left;
	margin: 5px 0px 3px 0;

	&.relative {
		display: inline-block;
		position: relative;
	}
}
.addEdit .header {
	font-family: Verdana;
	font-size: 11px;
	color: #ffffff;
	font-weight: bold;
	background-color: #8c8c8c;
	padding: 3px 10px 4px 10px;
	margin: 0 0 8px 0;
	width: 1200px;
}
.addEdit .logoImageContainer {
	position: relative;
	width:600px;
	height:90px;
	background-color: #ffffff;
	padding: 0 2px;
	border: 1px solid #eeeeee;
}
.addEdit .noLogoImageMessage {
	position: absolute;
	font: normal normal 14px Verdana, sans-serif;
	text-align: center;
	text-transform: uppercase;
	padding: 0;
	margin-top: -7px;
	color: #108bb1;
	width: 600px;
	height:40px;
	z-index: 4;
	top: 50%;
}
.addEdit .logoImage {
	position: absolute;
	width:100%;
	height:100%;
	z-index: 5;
}
.addEdit .welcomeImageContainer {
	position: relative;
	width:368px;
	height:265px;
	background-color: #e2e2e2;
}
.addEdit .noWelcomeImageMessage {
	position: absolute;
	font: normal normal 14px Verdana, sans-serif;
	text-align: center;
	text-transform: uppercase;
	padding: 0;
	margin-top: -16px;
	color: #108bb1;
	width: 368px;
	height:40px;
	z-index: 4;
	top: 50%;
}
.addEdit .welcomeImage {
	position: absolute;
	width:100%;
	height:100%;
	z-index: 5;
}
.addEdit .welcomeImageDisabledContainer {
	position: relative;
	width:368px;
	height:265px;
	background-color: #666666;
	z-index: 6;
	opacity: .80;
	/*filter: alpha(opacity=80);*/
}
.addEdit .welcomeImageDisabledMessage {
	position: absolute;
	font: normal normal 40px Verdana, sans-serif;
	text-align: center;
	text-transform: uppercase;
	padding: 0;
	margin-top: -25px;
	color: #ee0000;
	width: 368px;
	height:40px;
	z-index: 7;
	top: 50%;
}
.addEdit .bgAlert {
	background-color: #FF9999;
}
.alert {
	color: #ff0000;
	font-weight: normal !important;
}
.alignCenter {
	text-align: center;
}
.alignLeft {
	text-align: left;
}
.alignRight {
	text-align: right;
}


.bi td,
.bi td div {
	padding: 3px 0 5px 0;
	vertical-align: top;
	color: #646260;
	font: normal normal 11px Verdana;
	line-height: 14px;
}
.bi input,
.bi select {
	color: #646260;
	font: normal normal 11px Verdana;
	line-height: 14px;
}
.bi td.formlabel {
	padding-right: 10px;
}
.bi td.formlabelalert {
	padding-right: 10px;
	color: #ff0000;
}
.bic td {
	padding: 3px 20px 0 0;
	vertical-align: top;
}
.bic td,
.bic td div {
	color: #646260;
	font: normal normal 11px Verdana;
	line-height: 14px;
}
.clearFloat {
	clear: both;
	display: block;
	margin: 0;
	padding: 0;
	height: 0;
	line-height: 1px;
	font-size: 1px;
}
.designTemplateRel {
	position: relative;
}
.designTemplateAbs {
	position: absolute;
				margin: -136px 0 0 0px;
	width: 600px;
	padding: 20px;
	background-color: #fff7e8;
	border: 1px solid #a3a09a;
}
.displayBlock {
	display: block;
}
.displayNone {
	display: none;
}
.eRubeDivContainer {
	margin: 0;
	padding: 0;
}
.floatLeft {
	float: left;
}
.floatRight {
	float: right;
}
p.formlabel {
	font-weight:bold;
}
.formlabelalert {
	margin: 4px 0 2px 0;
	color: #ff0000;
	font-size: 10px;
}
.formLabelSub {
	font-style:italic;
	color:#646566;	
}

div.formfield {
	margin: 0 0 8px 0;
	font-size: 10px;
}
div.formlabel {
	margin: 4px 0 2px 0;
	color: #646566;
	font-size: 10px;
}
div.formlabelalert {
	margin: 4px 0 2px 0;
	color: #ff0000;
	font-size: 10px;
}

.highlight {
	background-color: #e6e6e6;
}

.noPad {
	padding: 0;
}
.rpt tr.header {
	background-color: #666666;
}
.rpt tr.header td {
	font-size: 12px;
	color: white;
	font-family: Verdana;
	font-weight: bold;
	text-decoration: underline;
	padding: 3px 10px 3px 5px;
}
.rpt tr.odd {
	background-color: #ffffff;
	vertical-align: top;
}
.rpt tr.odd td {
	padding: 3px 20px 3px 5px;
}
.rpt tr.even {
	background-color: #cccccc;
	vertical-align: top;
}
.rpt tr.even td {
	padding: 3px 20px 3px 5px;
}
.searchResultsImageContainer {
	position: relative;
	width:160px;
	height:131px;
	background-color: #e2e2e2;
}
.searchResultsNoImageMessage {
	position: absolute;
	font: normal normal 13px Verdana, sans-serif;
	text-align: center;
	text-transform: uppercase;
	padding: 0 10px;
	margin-top: -16px;
	color: #108bb1;
	width: 140px;
	height:40px;
	z-index: 4;
	top: 50%;
}

.searchResultsResortImage {
	position: absolute;
	width:100%;
	height:100%;
	z-index: 5;
}

.searchResultsResortImage a {
	display: block; 
	width: 100%; 
	height: 100%
}
.sectionWrpr {
	width: 1220px;
	margin: 0 0 20px 0;
}
.sectionHeader {
	font-family: Verdana;
	font-size: 11px;
	color: #ffffff;
	font-weight: bold;
	background-color: #8c8c8c;
	padding: 3px 10px 4px 10px;
	margin: 0 0 5px 0;
}
.small {
	font-size: 10px;
}
.smaller {
	font-size: 9px;
}
.tblAccessibility {
	margin: 0;
}
.tblAccessibility td {
	border-bottom: 1px solid #cccccc;
	width: 110px;
	padding: 0;
	text-align: center;
}
.tblAccessibility div {
	margin: 0 0 2px 0;
	padding: 0;
}

.cancellation-policy-rules {
	margin: 10px 0 0 0;

	tr.policy-rule {
		background-color: #ffffff;

		&.highlight {
			background-color: #e6e6e6;
		}

		input[type=text] {
			margin-left: 1px;
		}
	}

	th {
		font-weight: normal;
		vertical-align: bottom;
		text-align: left;
		padding: 3px 0 4px 0;
		background-color: #adadad;
	}

	td {
			padding: 7px 0;
			vertical-align: top;
	}

	.dts-from {
			width: 110px;
			padding-left: 3px
	}

	.dts-to {
			width: 110px;
	}

	.dab-from {
			width: 110px;
	}

	.dab-to {
			width: 110px;
	}

	.penalty-basis {
			width: 95px;
	}

	.penalty-value {
			width: 135px;
	}

	.cancel-commission {
			width: 85px;
	}

	.resuse-benefit {
			width: 95px;
	}

	.dollar-value-label {
		margin: 2px 3px 0 2px;
	}

	.percent-value-label {
		margin: 2px 0 0 2px;
	}

	.penalty-amount-dv-field {
		&:first-child {
			margin-top: 0;
		}
		margin-top: 5px;
	}

}

.tblHideInventory {
	margin: 0;
}
.tblHideInventory td {
	width: 160px;
	padding: 0;
	text-align: left;
}
.tblHideInventory div {
	margin: 0 0 4px 0;
	padding: 0;
}
.tblNoPadding td {
	padding: 0px;
}
.tblPricingVisibilityPolicy tr.odd,
.tblSpecialPricingPolicy tr.odd,
.tblPromotionPolicy tr.odd {
	background-color: #ffffff;
}
.tblPricingVisibilityPolicy tr.even,
.tblSpecialPricingPolicy tr.even,
.tblPromotionPolicy tr.even {
	background-color: #e6e6e6;
}
.tblPricingVisibilityPolicy th,
.tblSpecialPricingPolicy th,
.tblPromotionPolicy th {
	background-color: #adadad;
	vertical-align: bottom;
	text-align: left;
}
.tblPricingVisibilityPolicy th div,
.tblSpecialPricingPolicy th div,
.tblPromotionPolicy th div {
	font-weight: normal;
	padding: 3px 0 4px 0;
		margin: 0;
}
.tblPricingVisibilityPolicy td,
.tblSpecialPricingPolicy td,
.tblPromotionPolicy td{
	vertical-align: top;
	padding: 3px 0 5px 0;
}
.tblPricingVisibilityPolicy div,
.tblSpecialPricingPolicy div,
.tblPromotionPolicy div {
	margin: 3px 1px 1px 0;
	padding: 0;
}
.tblPricingVisibilityPolicy div div,
.tblSpecialPricingPolicy div div,
.tblPromotionPolicy div div {
	margin: 0;
	padding: 0;
}
.tblPricingVisibilityPolicy input,
.tblPricingVisibilityPolicy select,
.tblSpecialPricingPolicy input,
.tblSpecialPricingPolicy select,
.tblPromotionPolicy input,
.tblPromotionPolicy select {
	//height: 20px;
}
.tblPromotionPolicy div.clearFloat  {
	margin: 0px;
	padding: 0px;
}
.tblResults {
	font-family: Verdana; 
	font-size: 11px; 
	width:1220px;
}
.tblResults a.action:link,
.tblResults a.action:visited,
.tblResults a.action:active,
.tblResults a.action:hover {
	color: #ff0000;
}
a.disable:link,
a.disable:visited,
a.disable:active,
a.disable:hover,
.tblResults a.disable:link,
.tblResults a.disable:visited,
.tblResults a.disable:active,
.tblResults a.disable:hover {
	color: #ff0000;
}
a.enable:link,
a.enable:visited,
a.enable:active,
a.enable:hover,
.tblResults a.enable:link,
.tblResults a.enable:visited,
.tblResults a.enable:active,
.tblResults a.enable:hover {
	color: #008000;
}
.tblResults td.alert,
.tblResults td div.alert {
	color: #ff0000;
}
.tblResults td.header {
	font-family: Verdana; 
	font-size: 11px; 
	color: #ffffff; 
	font-weight: bold;
	background-color: #8c8c8c;
	padding: 3px 10px 4px 10px;
}
.tblResults td.record {
	padding: 3px 10px 4px 10px;
}
.tblResults td select {
	font-family: Verdana;
	font-size: 11px;
}
.tblSearch {
	padding: 18px 15px;
	margin: 0px;
	border: 1px solid #8c8c8c;
	background: #f0f0f0;
	border: 1px solid #8c8c8c;
	width:auto;
}
.tblSearch p.formLabel {
	font-family: Verdana; 
	font-size: 11px; 
	color: #000000; 
	font-weight: bold;
	margin-bottom: 1px;
}
.toolHeader {
	font-size:15px;
	font-weight:bold;
	color:#000000;
	margin:0px 0px 15px 0px;
}
.topLevelNav {
	width: 1220px; 
	border-top: solid 1px #7bacbc;
	border-bottom: solid 1px #7bacbc;
	margin: 0 0 15px 0;
}
.topLevelNav div.item {
	float:left;
	margin: 7px 0px 5px 0px;
}
.topLevelNav div.separator {
	float:left;
	margin: 7px;
	color: #7bacbc;
}
.toyBox {
	background: #eef9fd url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/bg_grad_table.gif") repeat-x top left;
	padding: 10px;
	margin: 0 0 15px 0;
	border: 1px solid #c8e1e9;
}
table.us th {
	background-color: transparent;
	border-top: 1px solid #c8c8c8;
	border-bottom: 1px solid #c8c8c8;
	padding: 5px 0;
	white-space: nowrap;
	color: #646260;
	font: normal normal 10px Verdana;
	text-align: left;
}

table.us td {
	padding: 4px 0;
	white-space: nowrap;
	color: #646260;
	font: normal normal 10px Verdana;
	vertical-align: middle;
}
table.us td.borderTop {
	border-top: 1px solid #c8c8c8;
}
table.us td.borderBottom {
	border-bottom: 1px solid #c8c8c8;
}
table.us .cellUnit {
	padding-right: 7px;
}
table.us .cellCalIcon {
	padding-right: 1px;
	width: 17px;
}
table.us .cellDates {
	padding-right: 15px;
}
table.us .cellPrice {
	padding-right: 7px;
	padding-left: 7px;
	text-align: right;
}
table.us .cellCurrency {
	text-align: left;
}
table.us .cellBook {
	text-align: right;
}
table.us .cellHold {
	text-align: right;
}
.navL1 {
	padding: 8px 0px 10px 0px; 
	border-bottom: solid 1px #999999; 
	border-top: solid 1px #999999;
	width: 1220px;
}
.cursorPointer {
	cursor: pointer
}
.vertAlignTop {
	vertical-align: top !important;
}

/* IDs */
#alertBlanket,
#confirmBlanket,
#passwordBlanket,
#eCertBlanket,
#processingBlanket,
#applyingpromoBlanket,
#removingpromoBlanket,
#applyingOverrideBlanket,
#removingOverrideBlanket {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: .75;
	/*filter: alpha(opacity=75);*/
	 z-index: 1000;
}
#dialogWrapper {
	position: fixed;
	top: 300px;
	left: 50%;
	margin: 0 0 0 -190px;
	z-index: 1500;

	#theBorder {
		background-color: #746f66;
		border: 1px solid #ffffff !important;
		padding: 1px;
		width: 380px;
		height: 100%;
		border-radius: 10px;
	}

	#theMsg {
		background-color: #ffffff !important;
		border: 1px solid #c0bebc;
		padding: 15px;
		height: 100%;
		border-radius: 10px;

		h1 {
			font-family: Verdana;
			font-size: 11px;
			color: #ffffff;
			font-weight: bold;
			background-color: #8c8c8c;
			padding: 3px 10px 4px 10px;
			margin: 0 0 5px 0;
		}

		ul {
			font-family: Verdana;
			font-size: 11px;
			margin: 0 0 15px 0;
		}

		li {
			font-family: Verdana;
			font-size: 11px;
			color: #000000;
			margin: 0 0 3px 0;
		}

		p,div {
			font-family: Verdana;
			font-size: 11px;
			color: #000000;
		}

		.popupHeader {
			color: #744165;
			font: normal bold 13px Trebuchet MS;
			text-transform: uppercase;
			margin: 0 0 4px 0;
		}

		.arrowBlueBtnMiddle,
		.noArrowBlueBtnMiddle {
				font-family: 'Lato',sans-serif;
				font-size: 10px;
				font-weight: bold;
				color: #ffffff;
		}

		.loader-image-container {
				background-image: url(@processingLoaderImage);
		}
	}
}
#loginDetails {
	float: right;
	margin: 15px 0 0 0;
}
#loginDetails p {
	font-size: 12px; 
	margin: 0 0 5px 0;
}
#loginDetails span {
	color: #7BACBC; 
}
#loginDetails a,
#loginDetails a:link,
#loginDetails a:visited,
#loginDetails a:hover {
	font-weight: normal; 
}
#loginDetails a:hover {
	color: #e22527; 
}
#logoHeader {
	width:1220px;
	margin: 10px 0 20px 0;
}
#main {
	width: 1220px;
}

#latinAmericaOnlySettings {
	background-color:#ffdddd;
	padding: 10px;
	margin: 20px 0;
	width: 1220px;
}

/* Sub Tabs Layers */
.subTabs {
	height: 25px;
	padding: 5px 10px 5px 10px;
	cursor: pointer;
}
.subTabsOff {
	cursor: pointer;
	border-top: solid 1px #666666;
	border-right: solid 1px #666666;
	border-bottom: solid 1px #666666;
	border-left: solid 1px #666666;
	background-color: #cccccc;
}
.subTabsOn {
	border-top: solid 2px #333333;
	border-right: solid 2px #333333;
	border-bottom: solid 2px #333333;
	border-left: solid 2px #333333;
	background-color: #cccccc;
}
.subTabsTextOff {
	color: #666666;
	font-weight: bold;
	font-size: 10px;
}
.subTabsTextOn {
	color: #000000;
	font-weight: bold;
	font-size: 10px;
}


hr.financialData {
	border: 0;
	background-color: #000000;
	padding: 0;
	margin: 3px 0 2px 0;
	width: 55px;
	height: 1px;
}
