.btn-addon-shared() {
	padding: 0 29px 0 10px;

	&:after {
		position: absolute;
		width: 0;
		height: 100%;
		border-left: 1px solid rgba(25, 25, 25, 0.5);
		border-right: 1px solid rgba(255, 255, 255, 0.5);
		right: 17px;
		content: "";
		top: 0px;
	}
}

.btn {

	button& {
		border: 0;
	}

	// default button (blue)
	#gradient .vertical(@btnGradientStart,@btnGradientEnd);
	display: inline-block;
	cursor: pointer;
	font-family: 'Lato',sans-serif;
	font-size: 11px;
	font-weight: normal;
	line-height: 1.7em;
	color: @btnDefaultFontColor;
	margin: 0;
	padding: 0 10px;
	border-radius: 3px;
	.box-shadow(1px 1px 3px -1px #666666);
	position: relative;
	behavior: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/res/PIE.htc");
	text-decoration: none;
	text-transform: capitalize;

	&:hover {
		#gradient .vertical(@btnHoverGradientStart ,@btnHoverGradientEnd);
		text-decoration: none;
	}
	&.arrow {

		.btn-addon-shared();

		// default arrow: right
		&:before {
			position:absolute;
			width: 0;
			height: 0;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-left: 4px solid white;
			content: "";
			right: 7px;
			top: 50%;
			margin-top: -4px;
		}
		&.down {
			&:before {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid white;
				right: 5px;
				margin-top: -2px;
			}
		}
	}

	&.remove {

		.btn-addon-shared();

		&:before {
			position:absolute;
			content: url("//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/remove_button_x.png");
			right: 4px;
			top: 50%;
			margin-top: -9px;
		}

	}


	&.blue {
		#gradient .vertical(#b4c8e1,#2f5a8d);
	}

	&.darkblue {
		#gradient .vertical(#779dd4,#001e60);
	}

	&.disabled {
		#gradient .vertical(#999999,#666666);
		cursor: default !important;
	}

	&.displayNone {
		display: none;
	}

	&.gray {
		#gradient .vertical(#c9c9c9,#8a8a8a);
	}

	&.large {
		font-size: 16px;
		border-radius: 5px;
		padding: 0 20px;
	}

	&.red {
		#gradient .vertical(#e96a70,#d10c11);
	}

	&.small {
		font-size: 10px;
		padding: 0 6px;

		&.arrow {
			.btn-addon-shared();
		}

		&.remove {
			.btn-addon-shared();
			&:before {
				margin-top: -3px;
			}
		}
	}

	&.x-small {
		font-size: 9px;
		padding: 0 6px;

		&.arrow {
			.btn-addon-shared();
		}

		&.remove {
			.btn-addon-shared();
			&:before {
				margin-top: -8px;
			}
		}
	}

	&.tall {
		line-height: 2.0em;
	}

	&.uppercase {
		text-transform: uppercase;
	}

	&.help {
		font-weight: bold;
	}
}

.buttons-container {
	margin-top: 12px;
	text-align: right;

	&.buttons-container-left-align {
		text-align: left;
	}

	& .buttons {

		.btn {
			&:first-child {
				margin-left: 0;
			}
			margin-left: 20px;
		}
	}

	& .buttons-copy {
		text-align: right;
		margin: 0 0 9px 0;
	}
}

.booking-buttons .js-one-click-disabled,
.cybersource-3ds-active .btn-fh-submit,
.js-fh-submitting .btn-fh-submit {

  background-color: @btnDisabledColor;
  #gradient .vertical(@btnDisabledGradientStart,@btnDisabledGradientEnd);
  color: transparent;
  transition: all .2s ease-out;
  cursor: not-allowed;
  padding: 0;

  &.arrow {
  	&:before {
  		border: none;
  	}
  }

  .loader-xs(@btnDefaultFontColor);
  &:after {
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -7px;
  }
}