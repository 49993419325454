.manual-booking-container {
	width: 698px;

	select, input, textarea {
		color: #646260;
	}

	.manual-booking-copy {
		margin: 0 0 12px 0;
		font-size: 11px;
		color: #646260;

		&.manual-booking-copy-re-enter-chd-info {
			margin: 5px 0;
		}
	}

	p {
		margin: 0 0 12px 0;
	}

	.booking-info-form-container {

		&.manual-booking-decrement-available-number {
			padding: 8px 0 0 0;
			margin: 8px 0 0 0;
			border-top: 1px solid #c8c8c8;
		}
	}

	.manual-booking-confirmation-info-fields {
		.booking-info-form-row {
			.column {
				width: 376px;

				.label {
					width: 150px;
				}

				.field-container {
					display: table-cell;
					max-width: 217px;

				}

				&.right {
					width: 300px;

					.label {
						text-align: right;
						width: 130px;
					}
				}
			}

			select {
				&.medium {
					width: 131px;
				}
			}
		}
	}

	.unit-summary-container {
		margin-top: 0px;

		.unit-summary {
			color: #646260;

			.unit-summary-table {
				color: #646260;

				td, th {
					color: #646260;
				}
			}
		}
	}


}
