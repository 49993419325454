.select-trans-specific-action-container {
	margin: 0 0 10px 0;
	
	& > * + * {
		margin-left: 20px;
	}

	select {
		margin: 1px 0 0 0;
	}
}

.reports-breadcrumb-nav_container {
	margin: 5px 0 12px 0;
}
