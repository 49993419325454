.unit-summary-container {
	margin-top: 30px;

	.resort-image {
		width: 160px;
		margin: 0 20px 0 0;
		padding: 0px;
	}
}

.unit-summary {
	width: 518px;

	.unit-summary-resort-info {
		margin: 0 0 15px 0;
	}

	.resort-name {
		font-family: 'Lato',sans-serif;
		font-weight: normal;
		font-size: 16px;
		color: #002b66;
		line-height: 20px;
		margin: 0;
		padding: 0;
	}

	.unit-summary-misc-info {
		border-top: 1px solid #c8c8c8;
		padding: 3px 0 1px 0;
		font-size: 10px;

		.label {
			width: 200px;
			font-weight: bold;
			margin-bottom: 3px;

			&:after {
				content: ":";
			}
		}

		.info {
			width: 300px;
			margin-bottom: 3px;
		}

		& + .unit-summary-table {
			margin-top: 0px;
		}
	}

	h3 {
			font-family: 'Lato', sans-serif;
			font-size: 11px;
			line-height: 14px;
			color: #13458a;
			margin: 0;
			padding: 0;
	}

	form {
		color: #646260;
		font: normal normal 10px Verdana;
		text-align: left;
	}

	.unit-summary-table {
		color: #646260;
		font-size: 10px;
		font-family: Verdana;
		margin: 12px 0 0 0;
		text-align: left;
		border-spacing: 0;
		border-collapse: collapse;

		.unit-summary-header {
			> th {
				border-top: 1px solid #c8c8c8;
				border-bottom: 1px solid #c8c8c8;
				padding-top: 5px;
				padding-bottom: 5px;
				vertical-align: bottom;
			}
		}

		td {
				padding: 4px 0;
				border-bottom: 1px solid #c8c8c8;
				vertical-align: top;
		}

		.cellUnit {
				width: 124px;
				padding-right: 7px;
		}

		.cellCalIcon {
				padding-right: 5px;
				width: 17px;
		}

		.cellDates {
				width: 160px;
				padding-right: 15px;
		}

		.cellTotalLabels {
				width: 99px;
				white-space: normal;
		}

		.cellPrice,
		.cellDiscount,
		.cellCancelPenalty {
				width: 72px;
				padding-right: 7px;
				text-align: right;
		}

		.cellDiscount,
		.cellCancelPenalty {
			color: #ff0000;
			div {
				color: #ff0000;
			}
		}

		.cellCurrency {
				width: 43px;
				text-align: left;
		}

	}

	.promo-container > * {
		margin-right: 10px;
	}

	.promo-name {
		width: 185px;
		margin-top: 2px;
	}

	.formlabel {
		font-weight: bold;
		margin-top: 2px;
	}

	.promo-code-field {
		width: 125px;
		height: 12px;
	}

	.btn {
		margin: 1px 0;
	}

	.cancelpenalty-container {
		margin-top: 6px;

		> * {
			margin-right: 10px;
		}

		.penalty-override-label {
			width: 105px;
		}
	}

	.cancel-penalty-override-field {
		width: 90px;
		height: 12px;
	}

	.cancel-penalty-tagline {
		margin: 4px 5px 4px 115px;
	}
}

