.forward-email-overlay-dialog-container {

	.forward-email-dialog-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000000;
		opacity: .75;
		 z-index: 1000;
	}
	
	.forward-email-dialog-container {
		position: fixed;
		top: 300px;
		left: 50%;
		margin: 0 0 0 -190px;
		z-index: 1500;

		.forward-email-dialog-border {
			background-color: #746f66;
			border: 1px solid #ffffff;
			padding: 1px;
			width: 380px;
			height: 100%;
			border-radius: 10px;
		}

		.forward-email-dialog-content-container {
			background-color: #ffffff;
			border: 1px solid #c0bebc;
			padding: 15px;
			height: 100%;
			border-radius: 10px;

			.forward-email-form-header {
				font-family: Verdana;
				font-size: 11px;
				color: #ffffff;
				font-weight: bold;
				background-color: #8c8c8c;
				padding: 3px 10px 4px 10px;
				margin: 0 0 5px 0;
			}

			.forward-email-form-content {
				margin: 10px 0;
			}

			label {
				margin: 0 0 2px 0;
				font-weight: bold;
			}

			input {
				width: 320px;
			}

			.forward-email-dialog-buttons-container {
				margin-top: 15px;
				text-align: right;

				.forward-email-dialog-buttons {
					.btn {
						&:first-child {
							margin-left: 0;
						}
						margin-left: 20px;
					}
				}
			}
		}
	}
}
