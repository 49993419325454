.edit-order-data-container {

	.edit-order-data-error-container {
		margin: 0 0 20px 0;
	}

	.edit-order-data-error {
		color: red;
		margin: 0 0 3px 0;
	}
	
	p {
		margin: 0 0 10px 0;
	}

	.editable-shared() {
		border: 1px solid black;
		background-color: #ffffff;
	}
	.non-editable-shared() {
		border: 1px solid gray;
		background-color: #eeeeee;
	}
	.financial-shared() {
		border: 1px solid green;
		background-color: #e5f5ea;
	}
	.credit-card-transaction-shared() {
		border: 1px solid orange;
		background-color: #feffa6;
	}
	.fallout-error-shared() {
		border: 1px solid red;
		background-color: #f3e4e2;
	}
	.modified-shared() {
		border: 1px solid blue;
		background-color: #ccfaff;
	}

	.edit-order-data-key-container {

		.edit-order-data-key-header {
			font-weight: bold;
			margin: 0 0 6px 0;
		}

		.edit-order-data-key-addon-shared() {
			padding: 0 0 10px 60px;

			&:before {
				position: absolute;
				width: 50px;
				height: 16px;
				border: 1px solid #000000;
				background-color: #999999;
				content: "";
				left: 0px;
				margin-top: -2px;
			}
		}

		.key {
			position: relative;
			.edit-order-data-key-addon-shared();

			&.editable:before {
				.editable-shared();
			}
			&.non-editable:before {
				.non-editable-shared();
			}
			&.financial:before {
				.financial-shared();
			}
			&.credit-card-transaction:before {
				.credit-card-transaction-shared();
			}
			&.fallout-error:before {
				.fallout-error-shared();
			}
			&.modified:before {
				.modified-shared();
			}
		}
	}

	.edit-order-data-form-container {

		.edit-order-data-row {
			.row();

			.label {
				width: 275px;
				line-height: 18px;
				font-weight: bold;
				padding: 0 0 0 10px;
				margin: 0 2px 2px 0;
			}

			.editable {
				.editable-shared();
				line-height: 21px;
			}
			.non-editable {
				.non-editable-shared();
			}
			.financial {
				.financial-shared();
			}
			.credit-card-transaction {
				.credit-card-transaction-shared();
			}
			.fallout-error {
				.fallout-error-shared();
			}
			.modified {
				.modified-shared();
			}

			.field-container {
				width: 400px;
				line-height: 18px;
				font-weight: normal;
				padding: 0 0 0 10px;

				input {
					width: 190px;
					margin: 1px 0;

					&.order-attempt-error {
						width: 330px;
					}
				}

				select {
					width: 196px;
					margin: 1px 0;
				}

				hr.financial {
					border: 0;
					background-color: #000000;
					padding: 0;
					margin: 3px 0 2px 0;
					width: 55px;
					height: 1px;
				}
			}

			.order-state {
				font-weight: bold;

				&.original {
					color: darkgreen;
				}
				&.cancelled {
					color: darkred;
				}
				&.modified {
					color: darkblue;
				}
				&.fallout {
					color: darkorange;
				}
				&.notcancelledbysupplier {
					color: #660000;
				}
			}
		}

		.edit-order-notes-container {
			margin: 20px 0 0 0;

			textarea	{
				width: 696px;
				margin: 0 0 20px 0;
			}

			label {
				display: block;
				font-weight: bold;
			}
		}
	}
}