.hold-step1,
.hold-step2,
.booking-step1,
.booking-step2,
.booking-step3 {

	.unit-summary-container {
		margin-top: 0px;
	}

}

.process-step-header {
	.clearfix();

	.process-step-image {
		float: right;
	}

	.bookingHeader {
		font-family: 'Lato',sans-serif;
		font-size: 25px;
		font-weight: normal;
		margin: 2px 0 18px 0;
		line-height: 26px;
		color: #002b66;
	}

	.process-steps-container {
		float: right;

		.process-step-circle {
			width: 32px;
			height: 32px;
			background: transparent;
			border: 4px solid #bec9da;
			border-radius: 50%;
			display:	inline-block;
			margin-left: 6px;
			font-family: Arial, sans-serif;
			font-size: 20px;
			color: #bec9da;
			text-align: center;
			line-height: 32px;

			&.process-step-circle-step-one {
				margin-left: 0;
				padding-right: 1px;
				width: 31px;
			}

			&.process-step-circle-current-step {
				color: #002b66;
				border-color: #002b66;
			}
		}
	}
}

.traveler-billing-payment-summary-container {
	margin-top: 20px;
}

.booking-info-form-container {
	display: table;
	font-size: 10px;
	color: #666666;

	.booking-info-form-row {
		display: table-row;
		.row();

		input[type='text'], input[type='password'], textarea {
			width: 191px;

			&.mini {
				width: 50px;
			}

			&.small {
				width: 75px;
			}

			&.medium {
				width: 125px;
			}

			&.xlarge {
				width: 552px;
			}
		}

		input[type='checkbox'] {
			margin: 0 8px 6px 0;
		}

		input[type='checkbox'] + .label {
			width: 280px;
			margin: -2px 0 0 0;
		}

		select {
			width: 197px;

			&.large {
				width: 250px;
			}

			&.medium {
				width: 110px;
			}

			&.small {
				width: 70px;
			}

			&.mini {
				width: 56px;
			}

			&.ccexpyear {
				margin-left: 16px;
			}
		}

		.column {
			display: table-row;
			margin: 3px 0;
			text-align: left;
			width: 315px;
			.row();
			
			.label {
				text-align: left;
				padding: 2px 9px 0 0;
				width: 109px;

				&.medium {
					font-size: 11px;
				}
			}

			.field-container {
				display: table-cell;
				max-width: 197px;

			}

			&.right {
				width: 361px;

				.label {
					text-align: right;
					width: 155px;
				}
			}

			&.full {
					width: 676px;

				.field-container {
					display: table-cell;
					width: auto;
				}

				input[type='checkbox'] + .label {
					width: 600px;
					margin: -2px 0 0 0;
				}

				&.right {
					.label {
						width: 470px;
					}
				}
			}
		}

		.required {
			&:before {
				content: "*";
			}
		}

		.alert {
			color: #d31900;
		}

		&.error {
			.column {
				margin: 0;
			}
		}
	}

	&.number-split-payments {

		select {
			width: 45px;
		}

		.column {
			&.full {
				.label {
					width: auto;
				}

				.field-container {
					display: table-cell;
					width: auto;
				}
			}
		}
	}

	&.select-traveler-info {
		padding: 0 0 8px 0;
		margin: 0 0 8px 0;
		border-bottom: 1px solid #c8c8c8;

		&.select-traveler-info-no-border {
			border: 0;
		}
	}

	&.cant-change-trav-info-link-container {
		width: 676px;
		margin: 5px 0 0 0;
	}
}

.booking-info-container {
	font-size: 11px;
	color: #666666;

	.alert {
		color: #d31900;
	}

	.booking-info-row {

		&:first-child {
			margin-bottom: 0;
		}
		margin-bottom: 10px;
	}

	.column {
		margin-right: 12px;
		text-align: left;
	}

	.col1,
	.col2 {
		width: 200px;
	}
	.col3 {
		width: 252px;
		margin-right: 0;
	}

	&.reservation-recall,
	&.edit-traveler-info {
		.col2 {
			width: 454px;
			margin-right: 10px;
		}			
	}

	.info-container {
		&:first-child {
			margin-top: 0;
		}
		margin-top: 10px;

		& .secondaryPhone {
			margin-top: 5px;
		}

		.btn {
			&:first-child {
				margin-top: 5;
			}
			margin-top: 10px;
		}
	}

	.splitPaymentCardInfo {
		&:first-child {
			margin-top: 0;
			border-top: 0;
			padding-top: 0;
		}
		padding: 10px 0 0 0;
		border-top: 1px solid #c8c8c8;
		width: 230px;
		margin: 10px 0 0 0;
	}
}

.bookingCopy {
	margin-top: 20px;
	font-size: 11px;
}

.booking-buttons-container {
	margin-top: 20px;
	text-align: right;

	&.booking-buttons-container-left-align {
		text-align: left;
	}

	.booking-buttons {

		.btn {
			&:first-child {
				margin-left: 0;
			}
			margin-left: 20px;
		}
	}

	.booking-buttons-copy {
		margin: 0 0 9px 0;
	}

	.booking-buttons-tagline {
		text-align: right;
		margin: 6px 0 0 0;
	}
}

.creditCardImages {
	float: right;
	margin: 0;

	div {
		margin: 0 0 0 7px;
	}
}

.helper-container {

	&.cid {
		left: 50%;
		margin: 60px 0 0;
	}
}

.state-selection-label {
	max-width: 150px;
	margin: 0 10px 0 0;
}

.state-selection-disclaimer {
	width: 528px;
	margin-top: 0;
}

/* Split Payment CSS Classes and IDs */

#splitPaymentsChargeTotal {
	background-color: #BBFFBB;
	border-top: 1px solid #009900;
	border-bottom: 1px solid #009900;
	padding: 1px 0;
	height: 25px;
	color: #009900;

	input {
		width: 75px;
	}

	&.invalid {
		background-color: #ffbbbb;
		border-top: 1px solid #bb0000;
		border-bottom: 1px solid #bb0000;
		color: #bb0000;
	}

	input[readonly] {
		color: #646260;
	}
}


#allSplitPaymentsFields {

	.splitPaymentCard {
		border-top: 1px solid #c8c8c8;
		padding: 10px 0;

		.approved {
			background-color: #bbffbb;
			color: #009900;
		}

		.declined {
			background: #ffbbbb;
			color: #bb0000;
		}
	}

	.box {
		&.splitPaymentDeclined {
			background: #ffbbbb;
			padding: 10px;
			margin: 0 0 15px 0;
			border: 1px solid #bb0000;
			color: #bb0000;
		}
	}
}

#splitPaymentsGrandTotal {
	margin-top: 5px;
	border-top: 1px solid #c8c8c8;
	padding: 3px 0;
	height: 20px;

	td.formlabel {
		padding: 3px 10px 0 0;
	}
}

#defaultPaymentFields {
	padding: 0;

	&.splitPayments {
		border-top: 1px solid #c8c8c8;
		margin-top: 5px;
		padding: 10px 0 0 0;
	}
}

.bi td {
		padding: 3px 0;
		vertical-align: top;

		&.nopad {
				padding: 0;
		}
}
.bi td  input[type='text'] {
		height: 13px;
}
.bi td.formlabel {
		padding: 5px 10px 0 0;
}
.bi td.formlabel div {
		padding: 0 10px 0 0;
}
.bi td.formlabelalert span {
		color: #ff0000;
}
.bi td.formlabelalert,
.bi td.formlabelalert div {
		padding: 5px 10px 0 0;
		color: #ff0000;
}
.bic td {
		padding: 3px 20px 0 0;
		vertical-align: top;
}
